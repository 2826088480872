<template>
  <v-container fluid>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Anmeldung hinzufügen</span>
        </v-card-title>
        <v-divider class="mb-5"></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.name"
                label="Name"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.elternkontakt[0].email"
                label="E-Mail"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.adresse"
                label="Adresse"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.plz"
                label="Postleihzahl"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.elternkontakt[0].telefon"
                label="Telefon"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.geburtstag"
                label="Geburtstag"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.allergien[0].name"
                label="Allergie"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.haftpflicht"
                label="Haftpflicht"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.schwimmen"
                label="Schwimmen"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.schwimmerlaubniss"
                label="Schwimmerlaubniss"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.essgewohnheit"
                label="Vegetarier"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.Schweinefleisch"
                label="Schweinefleisch"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.elternkontakt[0].name"
                label="Kontaktname"
                outlined
                required
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="anmeldung.mitglied"
                label="Mitglied"
                outlined
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="dialog = false">Abbrechen</v-btn>
          <v-btn color="green darken-1" text @click="AnmeldungSpeichern()"
            >Speichern</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="px-4 mt-5" cols="12">
      <v-col cols="10">
        <v-text-field
          outlined
          v-model="searchValue"
          label="Anmeldung Suchen"
          hint="Suche wird bei eingabe getätigt"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-chip class="mr-5" dark color="primary">
          Anmeldungen: {{ filteredMembers.length }}
        </v-chip>
        <v-btn color="primary" @click="dialog = !dialog"> Anmeldung hinzufügen </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="4"
        md="6"
        sm="12"
        v-for="member in filteredMembers"
        :key="member._id"
      >
        <v-card>
          <v-card-title>{{ member.name }}</v-card-title>
          <v-card-text>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Wert</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Email</td>
                    <td>{{ member.elternkontakt[0].email }}</td>
                  </tr>
                  <tr>
                    <td>Adresse</td>
                    <td>{{ member.adresse }}</td>
                  </tr>
                  <tr>
                    <td>Postleihzahl</td>
                    <td>{{ member.ort }}</td>
                  </tr>
                  <tr>
                    <td>Telefon</td>
                    <td>{{ member.elternkontakt[0].telefon }}</td>
                  </tr>
                  <tr>
                    <td>Geburtstag</td>
                    <td>
                      {{ calculateAge(member.geburtstag) }} ||
                      {{ member.geburtstag }}
                    </td>
                  </tr>
                  <tr v-for="allergie in member.allergien" :key="allergie.name">
                    <td>Allergie</td>
                    <td>{{ allergie.name }}</td>
                  </tr>
                  <tr>
                    <td>Haftpflicht</td>
                    <td>{{ member.haftpflicht }}</td>
                  </tr>
                  <tr>
                    <td>Schwimmen</td>
                    <td>{{ member.schwimmen }}</td>
                  </tr>
                  <tr>
                    <td>Schwimmen Erlaubt</td>
                    <td>{{ member.schwimmerlaubniss }}</td>
                  </tr>
                  <tr>
                    <td>Vegetarier</td>
                    <td>{{ member.essgewohnheit }}</td>
                  </tr>
                  <tr>
                    <td>Schweinefleisch</td>
                    <td>{{ member.Schweinefleisch }}</td>
                  </tr>
                  <tr>
                    <td>Mitglied</td>
                    <td>{{ member.mitglied }}</td>
                  </tr>
                  <tr>
                    <td>Email speichern</td>
                    <td>{{ getNewsletter(member.newsletter) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              :href="
                generateMailto(
                  member.elternkontakt[0].email
                    ? member.elternkontakt[0].email
                    : ''
                )
              "
              icon
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
            <v-btn @click="deleteMember(member._id)" icon>
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    selected: [],
    searchValue: "",
    members: [],
    loading: false,
    dialog: false,
    anmeldung: {
      name: "",
      geburtstag: "",
      adresse: "",
      elternkontakt: [
        {
          name: "",
          email: "",
          telefon: "",
        },
      ],
      ort: "",
      plz: "",
      strasse: "",
      email: "",
      haftpflicht: "",
      schwimmen: "",
      schwimmerlaubniss: "",
      essgewohnheit: "",
      Schweinefleisch: "",
      mitglied: "",
      allergien: [
        {
          name: "",
        },
      ],
      newsletter: false,
    },
  }),
  computed: {
    filteredMembers() {
      return this.members.filter((member) => {
        return (
          member.name.toLowerCase().includes(this.searchValue.toLowerCase()) ||
          member.adresse
            .toLowerCase()
            .includes(this.searchValue.toLowerCase()) ||
          member.ort.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      });
    },
    numberOfPages() {
      return Math.ceil(this.members.length);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "Name");
    },
  },
  methods: {
    getNewsletter(newsletter) {
      if (newsletter) {
        return "Ja";
      } else {
        return "Nein";
      }
    },
    generateMailto(email) {
      return "mailto:" + email;
    },
    deleteMember(id) {
      if (
        window.confirm(
          `Sind Sie sicher, dass Sie diese Anmeldung(${id}) löschen möchten?`
        ) == true
      ) {
        axios
          .delete("https://kjg-api.rezept-zettel.de/api/anmeldung/" + id)
          .then(() => {
            this.members = this.members.filter((member) => member._id !== id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    AnmeldungSpeichern() {
      this.loading = true;
      axios
        .post(
          "https://kjg-api.rezept-zettel.de/api/anmeldung/from-print",
          this.anmeldung
        )
        .then((response) => {
          this.loading = false;
          this.dialog = false;
          this.members.push(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getAnmeldungen() {
      this.loading = true;
      axios
        .get("https://kjg-api.rezept-zettel.de/api/anmeldung", {
          headers: {
            "auth-token": localStorage.getItem("Token"),
          },
        })
        .then((Response) => {
          this.members = Response.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err, err.stack);
          if (localStorage.getItem("RefreshToken") != undefined) {
            axios
              .post(
                "https://kjg-api.rezept-zettel.de/api/auth/refresh-token/",
                {
                  refreshToken: localStorage.getItem("RefreshToken"),
                }
              )
              .then((Response) => {
                const data = Response.data;
                localStorage.setItem("Token", data.accessToken);
                localStorage.setItem("RefreshToken", data.RefreshToken);
                this.getAnmeldungen();
              });
          }
        });
    },
    filterMembers(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
    calculateAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
  created() {
    this.getAnmeldungen();
  },
};
</script>

<style>
</style>